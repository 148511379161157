import { Routes, Route } from "react-router-dom";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

import AllMovies from "./Pages/AllMovies";
import Stats from "./Pages/Stats";
import Home from "./Pages/Home";
import Admin from "./Pages/Admin";
import Movie from "./Pages/Movies/[id]";

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Admin" element={<Admin />} />
          <Route path="/AllMovies" element={<AllMovies />} />
          <Route path="/Movies/:id" element={<Movie />} />

          <Route path="/Stats" element={<Stats />} />
        </Routes>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
