import React from "react";

function Profile({ profile }) {

  console.log(profile)

  return (
    <div className="">
      {" "}
      <div>
        <img src={profile.picture} alt="user_image" />
        <h2>Name: {profile.name}</h2>
        <h2>Email Address: {profile.email}</h2>
        <br />
        <br />
      </div>
    </div>
  );
}

export default Profile;
