import React from "react";

function MovieCard({ movies }) {
  return (
    <div className="movie">
      <div className="movie-img">
        <a href={`/Movies/${movies[0]}`}>
          <img src={movies[2]} alt="" />{" "}
          <div className="movie-info">
            <h3>{movies[0]} </h3>
            <span>{movies[1]}</span>
          </div>
        </a>
      </div>{" "}
    </div>
  );
}

export default MovieCard;
