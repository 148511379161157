import { useState, React } from "react";
import { Input, InputGroup } from "reactstrap";

function AddAChronic({ addANewChronic }) {

  const [image, setImage] = useState(null);

  let [addedMovie, setAddedMovie] = useState({
    title: "",
    rating: 0,
    text: "",
    image: image
  });


  const handleSubmit = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_LINK}/add_a_movie`, {
        method: "PUT",
        headers: { "Content-Type": "multipart/form-data" },
        body: JSON.stringify(addedMovie),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setAddedMovie({
      ...addedMovie,
      [evt.target.name]: value,
      image,
    });
  };

  const onChangeImage = async (e) => {
    const file = e.target.files[0];
    // send directly file as bytes, Once in the backend create a folder in Back and just take Id of it 
    // const base64 = await convertToBase64(file);
    setImage(file);
  };

  return (
    <>
      {" "}
      {addANewChronic ? (
        <div className="add-a-new-chronic">
          <form onChange={handleChange}>
            <InputGroup>
              <Input
                className="title"
                type="text"
                placeholder="MOVIE TITLE..."
                name="title"
                value={addedMovie.title}
                onChange={handleChange}
              />{" "}
              <div className="upload-image">
                <label>
                  UPLOAD IMAGE
                  <Input
                    type="file"
                    name="myFile"
                    accept=".jpeg, .png, .jpg"
                    onChange={(e) => onChangeImage(e)}
                    style={{ visibility: "hidden" }}
                  ></Input>
                </label>{" "}
              </div>
              <h1>RATING</h1>
              <Input
                className="rating"
                type="float"
                placeholder="0"
                name="rating"
                value={addedMovie.rating}
                onChange={handleChange}
              />
            </InputGroup>

            <Input
              className="text"
              type="textarea"
              placeholder="Write your chronicle here ... "
              name="text"
              value={addedMovie.text}
              onChange={handleChange}
            />
          </form>
          <div className="preview">
            <h2> PREVIEW </h2>
            <img src={image} alt="" />
            <h1>{addedMovie.rating}/10</h1>
            <div className="preview">
              <div className="title">{addedMovie.title}</div>
              <div className="text"> {addedMovie.text}</div>
            </div>
          </div>
          <div className="submit">
            <button onClick={handleSubmit}>
              <h1>ADD A NEW CHRONIC</h1>
            </button>
          </div>{" "}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default AddAChronic;
