import { useEffect, useState, React } from "react";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import "../index.css";

function Stats() {
  const [rowData, setRowData] = useState([]);
  const columnDefs = [
    {
      field: "MOVIE",
      sortable: true,
      filter: true,
    },
    {
      field: "RATING",
      sortable: true,
      filter: true,
    },
  ];

  const autoSizeStrategy = {
    type: "fitGridWidth",
    defaultMinWidth: 100,
    textAlign: "center",
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_LINK}/get_all_movies`)
      .then((result) => result.json())
      .then((rowData) => setRowData(rowData));
  }, []);

  const formatDataForAgGrid = (rowData) => {
    let titles = [];
    let ratings = [];

    rowData.forEach((v) => titles.push(v[0]));
    rowData.forEach((v) => ratings.push(v[1]));

    const result = [];
    for (let i = 0; i < titles.length; i++) {
      result.push({
        MOVIE: titles[i],
        RATING: ratings[i],
      });
    }
    return result;
  };

  let agGridRowData = formatDataForAgGrid(rowData);

  return (
    <>
      <a href="/" style={{ textDecoration: "none", color: "white" }}>
        <img className="website-logo" src={"./logo.png"} alt="logo" />{" "}
      </a>

      <div className="stats">
        <div className="ag-theme-alpine">
          <AgGridReact
            animateRows={true}
            rowSelection="multiple"
            columnDefs={columnDefs}
            rowData={agGridRowData}
            autoSizeStrategy={autoSizeStrategy}
          ></AgGridReact>
        </div>
      </div>

      <div className="bottom-banner">
        <div className="element">
          <Link
            to="/AllMovies"
            style={{ textDecoration: "none", color: "white" }}
          >
            <h1>ALL THE MOVIES</h1>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Stats;
