import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { Link } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
      <footer className="assirem-dev-signature">
        All Rights Reserved&nbsp; | &nbsp;&nbsp;
        <Link
          className="link"
          to={"https://www.assirem-dev.com"}
          target="_blank"
          rel="noreferrer noopener"
        >
          Assirem DEV
        </Link>
        &nbsp;@{new Date().getFullYear()}
      </footer>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
