import React from "react";
import axios from "axios";

function DeleteAChronic({ deleteAChronic, allMovies }) {
  let deleteAMovie = async (title) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_LINK}/delete_a_movie/${title}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {" "}
      {deleteAChronic ? (
        <div className="delete-a-chronic">
          <div className="all-movies-list">
            {Object.entries(allMovies).map(([_key, movie], i) => (
              <div key={i}>
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this Chronic ?"
                      )
                    )
                      deleteAMovie(movie[0]);
                  }}
                >
                  {movie[0]}
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default DeleteAChronic;
