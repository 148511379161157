import { useState, React } from "react";
import { Input, InputGroup } from "reactstrap";
import axios from "axios";

function ModifyAChronic({ modifyAChronic, allMovies }) {
  //   const [modifiedRating, setModifiedRating] = useState("");
  //   const [modifiedText, setModifiedText] = useState("");
  const [modifiedTitle, setModifiedTitle] = useState("");
  const [image, setImage] = useState("");

  let [modifiedMovie, setModifiedMovie] = useState({
    title: "",
    rating: 0,
    text: "",
  });

  const handleChange = (evt) => {
    const value = evt.target.value;

    setModifiedMovie({
      ...modifiedMovie,
      [evt.target.name]: value,
      image,
    });
  };

  const onChangeImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);

    setImage(base64);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const SubmitMovie = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_API_LINK}/modify_a_movie/${modifiedTitle}`,
        {
          method: "PUT",
          headers: { "Content-Type": "multipart/form-data" },
          body: JSON.stringify(modifiedMovie, image),
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  let selectAMovie = async (title) => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_LINK}/get_movie_from_id/${title}`
      );

      setModifiedTitle(data.data[0][0]);
      setImage(data.data[0][2]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="modify-a-chronic">
      {" "}
      {modifyAChronic ? (
        <div className="modify-a-chronic-container">
          <div className="all-movies-list">
            {Object.entries(allMovies).map(([_key, movie], i) => (
              <div key={i}>
                <button
                  onClick={() => {
                    selectAMovie(movie[0]);
                  }}
                >
                  {movie[0]}
                </button>
              </div>
            ))}
          </div>

          <div>
            <form className="modify-a-chronic" onChange={handleChange}>
              <InputGroup>
                <Input
                  className="title"
                  type="text"
                  placeholder="MOVIE TITLE..."
                  name="title"
                  value={modifiedTitle}
                  onChange={handleChange}
                />{" "}
                <div className="upload-image">
                  <label>
                    UPLOAD IMAGE
                    <Input
                      type="file"
                      name="myFile"
                      accept=".jpeg, .png, .jpg"
                      onChange={(e) => onChangeImage(e)}
                      style={{ visibility: "hidden" }}
                    ></Input>
                  </label>{" "}
                </div>
                <h1>RATING</h1>
                <Input
                  className="rating"
                  type="float"
                  placeholder="0"
                  name="rating"
                  value={modifiedMovie.rating}
                  onChange={handleChange}
                />
              </InputGroup>
              <Input
                className="text"
                type="textarea"
                placeholder="Write your chronicle here ... "
                name="text"
                value={modifiedMovie.text}
                onChange={handleChange}
              />
            </form>
            <div className="preview">
              <h2> PREVIEW </h2>
              <h1>{modifiedMovie.rating}/10</h1>
              <div className="preview">
                <img src={image} alt="" />
                <div className="title">{modifiedMovie.title}</div>
                <div className="text"> {modifiedMovie.text}</div>
              </div>
            </div>
            <div className="submit">
              <button onClick={() => SubmitMovie()}>
                <h1>MODIFY THE CHRONIC</h1>
              </button>
            </div>{" "}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default ModifyAChronic;
