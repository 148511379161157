import { useEffect, useState } from "react";
import MovieCard from "../Components/MovieCard";
import axios from "axios";
import { Link } from "react-router-dom";
import "../index.css";

function Home() {
    let [three_last_movies, setThree_last_movies] = useState("");

    let getMovies = async () => {
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_API_LINK}/get_three_last_movies`
          // `http://localhost:8000/get_three_last_movies`
        );
        setThree_last_movies(data.data);
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
      getMovies();
    }, []);
  
    return (
        <div className="App">
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                <img className="website-logo" src={"logo.png"} alt="logo" />
            </Link>
  
            <div className="movies">
                {Object.entries(three_last_movies).map(([_key, movie], i) => (
                    <div key={i}>
                        <MovieCard movies={movie} />
                    </div>
                ))}
            </div>
  
            <div className="bottom-banner">
                <div className="element">
                    <Link
                        to="/AllMovies"
                        style={{ textDecoration: "none", color: "white" }}
                    >
                        <h1>ALL THE MOVIES</h1>
                    </Link>
                </div>
                <div className="element">
                    <Link to="/Stats" style={{ textDecoration: "none", color: "white" }}>
                        <h1> STATS</h1>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Home