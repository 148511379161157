import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function Post() {
  let [movie, setMovie] = useState("");

  const { id } = useParams();

  let getMovie = async (id) => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_LINK}/get_movie_from_id/${id}`
      );
      setMovie(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMovie(id);
  }, [id]);

  return (
    <div>
      <Link to="/" style={{ textDecoration: "none", color: "white" }}>
        <img className="website-logo" src={"../logo.png"} alt="logo" />{" "}
      </Link>

      {Object.entries(movie).map(([_key, movieData], i) => (
        <div key={i}>
          <div className="movie-page">
            <div className="title">
              <h1>{movieData[0]} </h1>
            </div>
            <div className="img">
              <img src={movieData[2]} alt="" />
            </div>

            <span>
              {" "}
              <div className="rating">{movieData[1]} / 10</div>
            </span>
            <p>{movieData[3]}</p>

            <div className="publication-data-and-time">Publié le {movieData[4]}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Post;
