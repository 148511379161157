import axios from "axios";
import { useEffect, useState } from "react";
import MovieCard from "../Components/MovieCard";
import React from "react";
import "../index.css";
import { Link } from "react-router-dom";

function AllMovies() {
  let [movies, setMovies] = useState("");

  useEffect(() => {
    getAllMovies();
  }, []);

  let getAllMovies = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_LINK}/get_all_movies`
      );
      setMovies(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <a href="/" style={{ textDecoration: "none", color: "white" }}>
        <img className="website-logo" src={"./logo.png"} alt="logo" />{" "}
      </a>
      <div className="all-movies">
        {Object.entries(movies).map(([_key, movie], i) => (
            <div className="one-movie" key={i}>
              <MovieCard  movies={movie} />
            </div>
        ))}
      </div>

      <div className="bottom-banner">
                <div className="element">
                    <Link to="/Stats" style={{ textDecoration: "none", color: "white" }}>
                        <h1> STATS</h1>
                    </Link>
                </div>
            </div>
    </div>
  );
}

export default AllMovies;
