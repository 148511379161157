import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import AddAChronic from "../Components/AddAChronic";
import ModifyAChronic from "../Components/ModifyAChronic";
import DeleteAChronic from "../Components/DeleteAChronic";
import Profile from "../Components/Profile";
import { useState, useEffect } from "react";
import axios from "axios";

function Admin() {
  const [addANewChronic, setAddANewChronic] = useState(false);
  const [deleteAChronic, setDeleteAChronic] = useState(false);
  const [modifyAChronic, setModifyAChronic] = useState(false);

  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState(null);

  let [allMovies, setAllMovies] = useState("");

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  const getProfile = (user) => {
    if (user.length !== 0) {
      axios
        .get(`${process.env.REACT_APP_GOOGLE_URL_API}${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setProfile(res.data);
          localStorage.setItem("user", user.access_token);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    // const loggedInUser = localStorage.getItem("user");
    // if (loggedInUser) {
    //   // const foundUser = JSON.parse(loggedInUser);
    //   // getProfile(foundUser);
    // }

    if (user) {
      getProfile(user);
    }

    getMovies();
  }, [user]);

  let getMovies = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_LINK}/get_all_movies`
      );
      setAllMovies(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <a href="/" style={{ textDecoration: "none", color: "white" }}>
        <img className="website-logo" src={"logo.png"} alt="logo" />{" "}
      </a>
      <div>
        {profile ? (
          <Profile profile={profile} logOut={logOut} /> && (
            <>
              {" "}
              <div className="user-logged-in">
                <h1>User Logged in</h1>

                <button onClick={logOut}>Log out</button>
              </div>
              <div className="admin-page">
                <div>
                  <button
                    onClick={() => {
                      setAddANewChronic(!addANewChronic);
                    }}
                  >
                    <h1> ADD A NEW CHRONIC ? </h1>
                  </button>
                  <AddAChronic addANewChronic={addANewChronic} />
                </div>

                <div>
                  <button
                    onClick={() => {
                      setModifyAChronic(!modifyAChronic);
                    }}
                  >
                    <h1> MODIFY A CHRONIC ?</h1>
                  </button>

                  <ModifyAChronic
                    modifyAChronic={modifyAChronic}
                    allMovies={allMovies}
                  />
                </div>

                <div>
                  <button
                    onClick={() => {
                      setDeleteAChronic(!deleteAChronic);
                    }}
                  >
                    <h1> DELETE A CHRONIC ?</h1>
                  </button>

                  <DeleteAChronic
                    deleteAChronic={deleteAChronic}
                    allMovies={allMovies}
                  />
                </div>
              </div>
            </>
          )
        ) : (
          <button className="login" onClick={() => login()}>
            Sign in with Google 🚀{" "}
          </button>
        )}
      </div>{" "}
    </>
  );
}

export default Admin;
